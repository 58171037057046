import React from 'react';
import Link from 'components/base/Link';

const BlockAnnouncementAnnouncement = ({ data }) => {
  return (
    <section className="block bb">
      <div className="flex flex-wrap">
        <div className="w-100 w-50-md br0 bn br-md">
          <div className="aspect-ratio aspect-ratio--4x3 aspect-ratio--16x9-lg bb bn-md">
            <div className="aspect-ratio--object flex items-center justify-center">
              {data.linkLeft ? (
                <Link
                  to={data.linkLeft}
                  className="db w-100 page-header tc pt2 ph3 ph4-xl ttu gradient-hover-stroke"
                >
                  {data.titleLeft}
                </Link>
              ) : (
                <h3 className="db w-100 page-header tc pt2 ph3 ph4-xl ttu">
                  {data.titleLeft}
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 w-50-md">
          <div className="aspect-ratio aspect-ratio--4x3 aspect-ratio--16x9-lg">
            <div className="aspect-ratio--object flex items-center justify-center">
              {data.linkRight ? (
                <Link
                  to={data.linkRight}
                  className="db w-100 page-header tc pt2 ph3 ph4-xl ttu gradient-hover-stroke"
                >
                  {data.titleRight}
                </Link>
              ) : (
                <h3 className="db w-100 page-header tc pt2 ph3 ph4-xl ttu">
                  {data.titleRight}
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockAnnouncementAnnouncement;
