import React, { useState, useEffect } from 'react';
import fetch from 'cross-fetch';
import { Link } from 'gatsby';
import Layout from 'components/Layout';
import ImageUrl from 'components/base/ImageUrl';
import Event from 'components/modules/Event';
import BlockImage from 'components/modules/BlockImage';
import BlockRichText from 'components/modules/BlockRichText';
import BlockTextText from 'components/modules/BlockTextText';
import BlockAnnouncement from 'components/modules/BlockAnnouncement';
import BlockAnnouncementAnnouncement from 'components/modules/BlockAnnouncementAnnouncement';
import BlockLinksList from 'components/modules/BlockLinksList';
import classnames from 'classnames';
const esEndpoint =
  'https://oy5fxv675k.execute-api.us-east-2.amazonaws.com/default/mal-endpoint';
// import SEO from '../components/site/SEO';

const IndexPage = ({ data }) => {
  //get the last item in the nodes - this is in case they have a draft version
  const homePage = data.allContentfulHome.nodes.slice(-1)[0];
  const {
    heroImage,
    introDescription,
    featuredItem,
    events,
    announcements
  } = homePage;
  const allMalItem = data.allMalItem.nodes;
  // Get the featured catalog item from an accession number
  const [featuredCatalogItem, setFeaturedCatalogItem] = useState(null);
  useEffect(() => {
    fetch(esEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        query: {
          term: {
            'values.Accession Number': {
              value: featuredItem
            }
          }
        }
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.length) {
          data[0].image = data[0]._source.images.images
            ? data[0]._source.images.images[0]
            : data[0]._source.images['360_images']
            ? data[0]._source.images['360_images'][0]
            : null;
          setFeaturedCatalogItem(data[0]);
        }
      });
  }, [setFeaturedCatalogItem, featuredItem]);
  return (
    <Layout>
      <article className="container container--thin container--boxed">
        {/* the page header block */}
        <section className="block bb">
          <h3 className="page-header tc pt2 ph3 ph4-xl ttu">
            The Computer Lab of Your Dreams
          </h3>
        </section>
        {/* a block with a full width image */}
        <BlockImage data={heroImage} />
        {/* an intro description block */}
        {introDescription && (
          <>
            {introDescription.__typename === 'ContentfulBlockRichText' ? (
              <BlockRichText data={introDescription} className="bb" />
            ) : (
              <BlockTextText data={introDescription} className="bb" />
            )}
          </>
        )}
        {/* Catalog block */}
        {featuredCatalogItem && featuredCatalogItem.image && (
          <>
            <section className="block mt4">
              <h3 className="page-header pt2 ph3 ph4-xl ttu">
                <Link className="gradient-hover-stroke" to={`/collection/`}>
                  Catalog
                </Link>
              </h3>
            </section>
            <section className="block bb pv3 pv4-xl pv5-xxl">
              <div className="ph3 ph4-xl">
                <ImageUrl
                  src={featuredCatalogItem.image}
                  alt={featuredCatalogItem._source.name}
                />
                <ul className="flex justify-between body-copy mt4">
                  <li>Object of the day:</li>
                  <li className="dn db-md ttu">
                    {featuredCatalogItem._source.name}
                  </li>
                  <li className="tr tl-md">
                    <Link
                      to={`/collection/${featuredCatalogItem._source.slug}`}
                      className="gradient-hover"
                    >
                      View item page
                    </Link>
                  </li>
                </ul>
              </div>
            </section>
          </>
        )}
        {/* Events header block */}
        {events && events.length && (
          <>
            <section className="block mt4">
              <h3 className="page-header pt2 ph3 ph4-xl ttu">
                <Link className="gradient-hover-stroke" to={`/community/`}>
                  Events
                </Link>
              </h3>
            </section>
            {/* Events block */}
            <section className="block bb pv3 pv4-xl pv5-xxl">
              <ul className="list ph3 ph4-xl">
                {events.map((event, index) => {
                  return (
                    <Event
                      key={index}
                      data={event}
                      className={classnames({ bt: index !== 0 })}
                    />
                  );
                })}
              </ul>
            </section>
          </>
        )}
        {/* Centered text block */}
        {allMalItem && (
          <section className="block mt4 bb">
            <div className="flex flex-column items-center justify-center tc">
              <h3 className="db w-100 w-75-md w-60-xl page-header pt2 ph3 ph4-xl ttu">
                New Acquisitions!
              </h3>
              <ul className="body-copy pv3 pv4-xl pv5-xxl">
                {allMalItem.map((item, index) => {
                  return (
                    <li
                      className={classnames('db', { mt2: index > 0 })}
                      key={index}
                    >
                      <Link
                        to={`/collection/${item.slug}`}
                        className="di gradient-hover"
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        )}
        {/* Announcements */}
        {announcements.map((item, index) => {
          if (item.__typename === 'ContentfulBlockAnnouncement') {
            return <BlockAnnouncement key={index} data={item} />;
          } else {
            return <BlockAnnouncementAnnouncement key={index} data={item} />;
          }
        })}
        {/* Links list block */}
        <BlockLinksList isNavList={true} className="Home__footer" />
      </article>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query homePage {
    allMalItem(
      sort: { order: DESC, fields: orderedAccessionNumber }
      limit: 10
    ) {
      nodes {
        name
        accessionNumber
        slug
      }
    }
    allContentfulHome {
      nodes {
        heroImage {
          image {
            description
            fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        introDescription {
          __typename
          ... on Node {
            ... on ContentfulBlockRichText {
              title
              description {
                json
              }
              twoColumn
            }
            ... on ContentfulBlockTextText {
              title
              textLeft {
                json
              }
              textRight {
                json
              }
            }
          }
        }
        featuredItem
        events {
          ... on Node {
            ... on ContentfulBlockEvent {
              title
              date
              image {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              description {
                json
              }
            }
          }
        }
        announcements {
          ... on Node {
            ... on ContentfulBlockAnnouncement {
              __typename
              title
              link
            }
            ... on ContentfulBlockAnnouncementAnnouncement {
              __typename
              titleLeft
              titleRight
              linkLeft
              linkRight
            }
          }
        }
      }
    }
  }
`;
