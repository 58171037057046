import React from 'react';
import Link from 'components/base/Link';

const BlockAnnouncement = ({ data }) => {
  return (
    <section className="block bb">
      <div className="aspect-ratio aspect-ratio--4x3 aspect-ratio--16x9-lg">
        <div className="aspect-ratio--object flex items-center justify-center">
          {data.link ? (
            <Link
              to={data.link}
              className="db w-100 w-75-md w-60-xl page-header tc pt2 ph3 ph4-xl ttu gradient-hover-stroke"
            >
              {data.title}
            </Link>
          ) : (
            <h3 className="db w-100 w-75-md w-60-xl page-header tc pt2 ph3 ph4-xl ttu">
              {data.title}
            </h3>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlockAnnouncement;
